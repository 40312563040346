var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoScrollBlock"},[(_vm.title)?_c('p',{staticClass:"bold fs20 margin4",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.secondTitle)?_c('p',{class:{ margin5: !_vm.secondDesc },domProps:{"innerHTML":_vm._s(_vm.secondTitle)}}):_vm._e(),(_vm.secondDesc)?_c('p',{staticClass:"fs13 margin5",domProps:{"innerHTML":_vm._s(_vm.secondDesc)}}):_vm._e(),(_vm.data.text)?_c('p',{staticClass:"margin5",domProps:{"innerHTML":_vm._s(_vm.data.text)}}):_vm._e(),_c('div',{staticClass:"photoScroll",class:{ margin4: _vm.data.bottomText }},[(!_vm.touchDevice)?_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll.x",modifiers:{"x":true}}],staticClass:"photoScroll__container",on:{"dragscrollmove":function($event){return _vm.dragScroll($event)}}},[_c('div',{staticClass:"photoScroll__photos"},_vm._l((_vm.data.images),function(image,index){return _c('div',{key:index,style:({ width: _vm.imgWidth + 'px' + '' + '!important' })},[(image.title)?_c('p',{staticClass:"margin7 bold fs20",domProps:{"innerHTML":_vm._s(image.title)}}):_vm._e(),_c('div',{class:[
              { clickablePhoto: _vm.clickable, margin5: image.desc },
              _vm.data.imagesClass,
            ],on:{"click":function($event){return _vm.openPopupSlider(index)},"mousedown":function($event){return _vm.mouseDown()},"mouseup":function($event){return _vm.mouseUp()}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image.image),expression:"image.image"}],class:{ clickable: _vm.clickable }})]),(image.desc)?_c('p',{class:_vm.descClass,domProps:{"innerHTML":_vm._s(image.desc)}}):_vm._e()])}),0)]):_c('VuePerfectScrollbar',{staticClass:"photoScroll__container",attrs:{"settings":_vm.scrollSettings},on:{"ps-scroll-x":function($event){return _vm.dragScroll($event)}}},[_c('div',{staticClass:"photoScroll__photos"},_vm._l((_vm.data.images),function(image,index){return _c('div',{key:index,style:({ width: _vm.imgWidth + 'px' })},[(image.title)?_c('p',{staticClass:"margin7 bold fs20",domProps:{"innerHTML":_vm._s(image.title)}}):_vm._e(),_c('div',{class:[
              {
                clickablePhoto: _vm.clickable,
                margin5: image.desc,
                imageBorder: _vm.data.imageBorder,
              },
              _vm.data.imagesClass,
            ],on:{"click":function($event){return _vm.openPopupSlider(index)},"mousedown":function($event){return _vm.mouseDown()},"mouseup":function($event){return _vm.mouseUp()}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image.image),expression:"image.image"}],class:{ clickable: _vm.clickable }})]),(image.desc)?_c('p',{class:_vm.descClass,domProps:{"innerHTML":_vm._s(image.desc)}}):_vm._e()])}),0)]),_c('vue-slider',{attrs:{"tooltip":"none","dotSize":"42","height":"3px","min":_vm.min,"max":_vm.max},on:{"dragging":_vm.dragSlider},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(_vm.data.bottomText)?_c('p',{staticClass:"lh28",domProps:{"innerHTML":_vm._s(_vm.data.bottomText)}}):_vm._e(),_c('ImagesPopupSlider',{ref:"imagesPopupSlider"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }