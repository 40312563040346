<template>
  <div class="photoScrollBlock">
    <p class="bold fs20 margin4" v-if="title" v-html="title"></p>
    <p
      v-if="secondTitle"
      v-html="secondTitle"
      :class="{ margin5: !secondDesc }"
    ></p>
    <p class="fs13 margin5" v-if="secondDesc" v-html="secondDesc"></p>

    <p v-html="data.text" class="margin5" v-if="data.text"></p>
    <div class="photoScroll" :class="{ margin4: data.bottomText }">
      <div
        class="photoScroll__container"
        v-dragscroll.x
        v-on:dragscrollmove="dragScroll($event)"
        v-if="!touchDevice"
      >
        <div class="photoScroll__photos">
          <div
            v-for="(image, index) in data.images"
            :key="index"
            :style="{ width: imgWidth + 'px' + '' + '!important' }"
          >
            <p
              class="margin7 bold fs20"
              v-if="image.title"
              v-html="image.title"
            ></p>
            <div
              :class="[
                { clickablePhoto: clickable, margin5: image.desc },
                data.imagesClass,
              ]"
              @click="openPopupSlider(index)"
              @mousedown="mouseDown()"
              @mouseup="mouseUp()"
            >
              <img v-lazy="image.image" :class="{ clickable: clickable }" />
            </div>
            <p :class="descClass" v-if="image.desc" v-html="image.desc"></p>
          </div>
        </div>
      </div>
      <VuePerfectScrollbar
        class="photoScroll__container"
        :settings="scrollSettings"
        @ps-scroll-x="dragScroll($event)"
        v-else
      >
        <div class="photoScroll__photos">
          <div
            v-for="(image, index) in data.images"
            :key="index"
            :style="{ width: imgWidth + 'px' }"
          >
            <p
              class="margin7 bold fs20"
              v-if="image.title"
              v-html="image.title"
            ></p>
            <div
              :class="[
                {
                  clickablePhoto: clickable,
                  margin5: image.desc,
                  imageBorder: data.imageBorder,
                },
                data.imagesClass,
              ]"
              @click="openPopupSlider(index)"
              @mousedown="mouseDown()"
              @mouseup="mouseUp()"
            >
              <img v-lazy="image.image" :class="{ clickable: clickable }" />
            </div>
            <p :class="descClass" v-if="image.desc" v-html="image.desc"></p>
          </div>
        </div>
      </VuePerfectScrollbar>
      <vue-slider
        v-model="value"
        tooltip="none"
        dotSize="42"
        height="3px"
        :min="min"
        :max="max"
        @dragging="dragSlider"
      >
      </vue-slider>
    </div>
    <p class="lh28" v-html="data.bottomText" v-if="data.bottomText"></p>
    <ImagesPopupSlider ref="imagesPopupSlider"></ImagesPopupSlider>
  </div>
</template>



<style lang="sass">
@import '@/assets/sass/Slider'
@import '@/assets/sass/PhotoScroll'

.image__desc__tsi
  color: #8C8C8C
  margin-top: 12px
  text-align: center

.imageBorder
  border: 1px solid #939393
  border-radius: 16px
  padding: 24px
  img
    width: 100%
    height: 100%
    border: 1px solid #A7ABAF
    border-radius: 8px

@media screen and (max-width: 1200px)
  .imageBorder
    padding: 12px

@media screen and (max-width: 768px)
  .imageBorder
    padding: 6px
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { dragscroll } from "vue-dragscroll";

import ImagesPopupSlider from "@/components/app/ImagesPopupSlider";

export default {
  props: [
    "data",
    "title",
    "secondTitle",
    "secondDesc",
    "clickable",
    "imgWidth",
    "case",
  ],
  data: () => ({
    value: 0,
    min: 0,
    max: 1000,
    leftPos: 0,
    noClickEvent: false,
    ismouseDown: false,
    touchDevice: false,
    scrollSettings: {
      suppressScrollY: true,
    },
    images: [],
  }),
  computed: {
    descClass() {
      if (this.case === "caseTsi") {
        return "image__desc__tsi";
      }
    },
  },
  methods: {
    dragSlider: function () {
      var position = (this.value / this.max) * 100;
      var element = this.$el.getElementsByClassName("photoScroll__photos")[0];
      var container = this.$el.getElementsByClassName(
        "photoScroll__container"
      )[0];
      var width = this.$el.offsetWidth;

      var childrens = element.childNodes;
      var fullWidth = 0;
      var marginRight = +getComputedStyle(childrens[0]).marginRight.replace(
        "px",
        ""
      );

      childrens.forEach(function (element, index) {
        fullWidth += element.offsetWidth;
      });

      fullWidth += childrens.length * marginRight - marginRight;

      container.scrollLeft = ((fullWidth - width) / 100) * position;
    },
    blockWidth: function () {
      var photos = this.$el.getElementsByClassName("photoScroll__photos")[0];
      var element = this.$el.getElementsByClassName(
        "photoScroll__container"
      )[0];
      var containerWidth =
        this.$el.getElementsByClassName("photoScroll")[0].offsetWidth;
      var windowWidth = document.documentElement.clientWidth;
      var leftPos = this.$el.offsetLeft;
      var rightOffset = windowWidth - (leftPos + containerWidth);

      if (
        this.data.fullwidth &&
        window.matchMedia("(min-width: 1199px)").matches
      ) {
        element.style.width = windowWidth + "px";
        element.style.marginLeft = "-" + leftPos + "px";
        element.style.paddingLeft = leftPos + "px";
      } else {
        element.style.width = windowWidth - leftPos + "px";
        element.style.marginLeft = null;
        element.style.paddingLeft = null;
      }

      var childrens = photos.childNodes;
      childrens[childrens.length - 1].style.marginRight = rightOffset + "px";

      if (photos.offsetWidth <= element.offsetWidth) {
        this.$el.getElementsByClassName("vue-slider")[0].style.display = "none";
      } else {
        this.$el.getElementsByClassName("vue-slider")[0].style.display =
          "block";
      }
    },
    dragScroll: function (event) {
      var comp = this;
      var leftPos = event.target.scrollLeft;

      var element = this.$el.getElementsByClassName("photoScroll__photos")[0];
      var container = this.$el.getElementsByClassName(
        "photoScroll__container"
      )[0];
      var width = this.$el.offsetWidth;

      var childrens = element.childNodes;
      var fullWidth = 0;
      var marginRight = +getComputedStyle(childrens[0]).marginRight.replace(
        "px",
        ""
      );

      childrens.forEach(function (element, index) {
        fullWidth += element.offsetWidth;
      });

      fullWidth += childrens.length * marginRight - marginRight;

      this.value = parseInt(this.max * (leftPos / (fullWidth - width)));

      if (comp.ismouseDown) {
        comp.noClickEvent = true;
      }
    },
    mouseDown: function () {
      var comp = this;
      comp.ismouseDown = true;
    },
    mouseUp: function () {
      var comp = this;
      setTimeout(function () {
        comp.noClickEvent = false;
      });
    },
    openPopupSlider: function (index) {
      if (this.clickable && !this.noClickEvent) {
        this.$refs.imagesPopupSlider.openPopupSlider(this.images, index);
      }
    },
  },
  mounted() {
    this.blockWidth();

    if (document.body.classList.contains("touchDevice")) {
      this.touchDevice = true;
    }
    var images = [];
    this.data.images.forEach(function (element, index) {
      images.push(element.fullImage);
    });
    this.images = images;

    const slider = document.querySelectorAll(".vue-slider");
    const sliderProgress = document.querySelector(".vue-slider-process");
    const sliderRail = document.querySelector(".vue-slider-rail");
    const sliderDotBlock = document.querySelector(".photoScroll__photos > div");
    const sliderDot = document.querySelector(".vue-slider-dot-handle");

    if (this.case === "caseTsi") {
      slider.forEach((item) => {
        // item.style.backgroundColor = "#FFFFFF";
        item.style.cssText =
          "padding-right: 40px !important; background-color: #FFFFFF";

        item.querySelector(".vue-slider-process").style.backgroundColor =
          "#FAAF32";
        item.querySelector(".vue-slider-rail").style.cssText =
          "background-color: #fff; width: 100%; margin-left: 0; height: 3px";

        item.querySelector(".vue-slider-dot-handle").style.cssText =
          "background-color: #faaf32; border-radius: 4px";

        item.querySelector(".vue-slider-dot").style.marginLeft = "20px";
      });
    }
  },
  created: function () {
    var comp = this;
    window.addEventListener("scroll", this.blockWidth);
    window.addEventListener("resize", this.blockWidth);
    window.addEventListener("scroll", this.dragSlider);
    window.addEventListener("resize", this.dragSlider);
    document.body.addEventListener("touchstart", function () {
      comp.touchDevice = true;
    });
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.blockWidth);
    window.removeEventListener("resize", this.blockWidth);
    window.removeEventListener("scroll", this.dragSlider);
    window.removeEventListener("resize", this.dragSlider);
  },
  directives: {
    dragscroll,
  },
  components: {
    VueSlider,
    ImagesPopupSlider,
    VuePerfectScrollbar,
  },
};
</script>

<!-- <template>
	<div class="photoScrollBlock">
		<p class="bold fs20 margin4" v-if="title" v-html="title"></p>
		<p v-if="secondTitle" v-html="secondTitle" :class="{margin5: !secondDesc}"></p>
		<p class="fs13 margin5" v-if="secondDesc" v-html="secondDesc"></p>

		<p v-html="data.text" class="margin5" v-if="data.text"></p>
		<VuePerfectScrollbar class="photoScroll" :settings="scrollSettings" :class="{margin4: data.bottomText}">
			<div class="photoScroll__photos">
				<div v-for="image in data.images">
					<img :src="image.image" :class="{margin5: image.desc}">
					<p v-if="image.desc" v-html="image.desc"></p>
				</div>
			</div>
		</VuePerfectScrollbar>
		<p class="lh28" v-html="data.bottomText" v-if="data.bottomText"></p>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/PhotoScroll';
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	export default {
		props: ['data', 'title', 'secondTitle', 'secondDesc'],
		data: () => ({
			scrollSettings: {
				minScrollbarLength: 41,
				maxScrollbarLength: 41,
				suppressScrollY: true,
			}
		}),
		components: {
			VuePerfectScrollbar
		}
	}
</script> -->